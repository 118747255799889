<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      'Menu': 'Menu',
      'Dashboard': 'Painel',
      'Register': 'Cadastrar',
      'Financial': 'Financeiro',
      'Awards': 'Premiação',
      'Entities': 'Entidades',
      'Donations': 'Doações',
      'Donate': 'Doar',
      'Extract': 'Extrato',
      'Products': 'Produtos',
      'Network': 'Rede',
      'Profit': 'Rendimento',
      'Donation': 'Doação',
      'Balance': 'Saldo',
      'Voucher': 'Voucher',
      'Transfer': 'Transferir',
      'Withdrawal': 'Sacar',
      'Withdrawal History': 'Histórico de Saque',
      'Sponsored': 'Indicados',
      'Team': 'Equipe',
      'Binary': 'Binário',
      'Reports': 'Relatórios',
      'Bonus Referral': 'Bônus Indicação',
      'Bonus Binary': 'Bônus Binário',
      'Bonus Profit': 'Bônus Rendimento',
      'Graduation': 'Graduação',
      'My Donations': 'Minhas Doações',
      'Support': 'Suporte',
      'Downloads': 'Downloads',
      'FAQ': 'FAQ',
      'Tickets': 'Tickets',
      'Invoices': 'Notas Fiscais',
      'My Invoices': 'Minhas Notas',
      'Listing': 'Lista',
      'Send': 'Enviar',
    },
    es: {
      'Menu': 'Menu',
      'Dashboard': 'Panel',
      'Register': 'Registro',
      'Financial': 'Financiero',
      'Awards': 'Premios',
      'Entities': 'Entidades',
      'Donations': 'Donaciones',
      'Donate': 'Donar',
      'Extract': 'Extracto',
      'Products': 'Productos',
      'Network': 'Red',
      'Profit': 'Rendimiento',
      'Donation': 'Donación',
      'Balance': 'Saldo',
      'Voucher': 'Cupón',
      'Transfer': 'Transferir',
      'Withdrawal': 'Retirada',
      'Withdrawal History': 'Historia de Retirada',
      'Sponsored': 'Patrocinados',
      'Team': 'Equipo',
      'Binary': 'Binario',
      'Reports': 'Informes',
      'Bonus Referral': 'Bonus Patrocinio',
      'Bonus Binary': 'Bonus Binario',
      'Bonus Profit': 'Bonus Rendimiento',
      'Graduation': 'Graduación',
      'My Donations': 'Mis Donaciones',
      'Support': 'Soporte',
      'Downloads': 'Descargas',
      'FAQ': 'FAQ',
      'Tickets': 'Tickets',
      'Invoices': 'Facturas',
      'My Invoices': 'Mis facturas',
      'Send': 'Enviar',
    }
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  mounted: function() {
    document.body.setAttribute("data-sidebar", "dark");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="menu-title">{{ t('Menu') }}</li>
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span>{{ t('Dashboard') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" :to="'/register/'+account.user.username" class="side-nav-link-ref">
          <i class="bx bxs-user-plus"></i>
          <span>{{ t('Register') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Leilão') }}</li>
      <li>
        <router-link tag="a" to="/auction" class="side-nav-link-ref">
          <i class="bx bx-shopping-bag"></i>
          <span>{{ t('Products') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/auction/bids" class="side-nav-link-ref">
          <i class="bx bx-list-check"></i>
          <span>{{ t('Meus Lances') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/auction/awards" class="side-nav-link-ref">
          <i class="bx bx-award"></i>
          <span>{{ t('Meus Prêmios') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Donations') }}</li>
      <li>
        <router-link tag="a" to="/entities" class="side-nav-link-ref">
          <i class="bx bxs-bookmark-star"></i>
          <span>{{ t('Entities') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/wallet/deposit" class="side-nav-link-ref">
          <i class="bx bx-dollar-circle"></i>
          <span>{{ t('Donate') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/reports/orders" class="side-nav-link-ref">
          <i class="bx bx-list-ol"></i>
          <span>{{ t('My Donations') }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t('Invoices') }}</li>
      <li>
        <router-link tag="a" to="/invoices/create" class="side-nav-link-ref">
          <i class='bx bxs-file-plus'></i>
          <span>{{ t('Send') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/invoices" class="side-nav-link-ref">
          <i class="bx bx-file"></i>
          <span>{{ t('My Invoices') }}</span>
        </router-link>
      </li>
      <!-- <li class="menu-title">{{ t('Sorteios') }}</li>
      <li>
        <router-link tag="a" to="/sorteios/rounds" class="side-nav-link-ref">
          <i class="bx bx-tv"></i>
          <span>{{ t('Próximas Rodadas') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/sorteios/cards" class="side-nav-link-ref">
          <i class="bx bx-grid-horizontal"></i>
          <span>{{ t('Minhas Cartelas') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/sorteios/orders" class="side-nav-link-ref">
          <i class="bx bx-list-ol"></i>
          <span>{{ t('Meus Pedidos') }}</span>
        </router-link>
      </li> -->
      <li class="menu-title">{{ t('Financial') }}</li>
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bx-list-check"></i>
          <span>{{ t('Cash Back') }}</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><router-link tag="a" to="/wallet/extract" class="side-nav-link-ref">{{ t('Extract') }}</router-link></li>
          <li><router-link tag="a" to="/wallet/transfer" class="side-nav-link-ref">{{ t('Transfer') }}</router-link></li>
          <li><router-link tag="a" to="/wallet/withdrawal" class="side-nav-link-ref">{{ t('Withdrawal') }}</router-link></li>
          <li><router-link tag="a" to="/wallet/withdrawal/history" class="side-nav-link-ref">{{ t('Withdrawal History') }}</router-link></li>
        </ul>
      </li>
      <li>
        <a href="javascript: void(0);" class="has-arrow">
          <i class="bx bx-list-check"></i>
          <span>{{ t('Bônus') }}</span>
        </a>
        <ul class="sub-menu" aria-expanded="false">
          <li><router-link tag="a" to="/wallet/bonus/extract" class="side-nav-link-ref">{{ t('Extract') }}</router-link></li>
        </ul>
      </li>
      <li class="menu-title">{{ t('Network') }}</li>
      <li>
        <router-link tag="a" to="/network/sponsored" class="side-nav-link-ref">
          <i class="bx bx-user"></i>
          <span>{{ t('Sponsored') }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/network/team" class="side-nav-link-ref">
          <i class="bx bx-group"></i>
          <span>{{ t('Team') }}</span>
        </router-link>
      </li>
      <!-- <li>
        <router-link tag="a" to="/network/binary" class="side-nav-link-ref">
          <i class="bx bx-street-view"></i>
          <span>{{ t('Binary') }}</span>
        </router-link>
      </li> -->
      <!-- <li class="menu-title">{{ t('Reports') }}</li>
      <li>
        <router-link tag="a" to="/reports/referral" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span>{{ t('Bonus Referral') }}</span>
        </router-link>
      </li> -->
      <!-- <li>
        <router-link tag="a" to="/reports/binary" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span>{{ t('Bonus Binary') }}</span>
        </router-link>
      </li> -->
      <!-- <li>
        <router-link tag="a" to="/reports/profit" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span>{{ t('Bonus Profit') }}</span>
        </router-link>
      </li> -->
      <!-- <li>
        <router-link tag="a" to="/reports/graduation" class="side-nav-link-ref">
          <i class="bx bx-crown"></i>
          <span>{{ t('Graduation') }}</span>
        </router-link>
      </li> -->
      <li class="menu-title">{{ t('Support') }}</li>
      <!-- <li>
        <router-link tag="a" to="/downloads" class="side-nav-link-ref">
          <i class="bx bx-download"></i>
          <span>{{ t('Downloads') }}</span>
        </router-link>
      </li> -->
      <!-- <li>
        <router-link tag="a" to="/faq" class="side-nav-link-ref">
          <i class="bx bx-question-mark"></i>
          <span>{{ t('FAQ') }}</span>
        </router-link>
      </li> -->
      <li>
        <router-link tag="a" to="/tickets" class="side-nav-link-ref">
          <i class="bx bx-conversation"></i>
          <span>{{ t('Atendimento') }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
